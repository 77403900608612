/* https://github.com/csstools/postcss-normalize */
@import-normalize;

html {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
main,
footer,
header,
nav {
  margin: 0;
  padding: 0;
  border: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus,
button:focus,
textarea:focus {
  outline: none;
}
